<template>
  <div class="background red-background">
    <div class="content-cell">
      <div class="heading">{{ $t('managePlayers') }}</div>
      <div v-for="(player, idx) in players"
           :key="'player_' + player.number"
           class="entry-wrapper">
        <div class="player-number">{{ player.number }}.</div>
        <div class="input-wrapper">
          <input type="text"
                 class="input"
                 v-model="player.name"
                 @change="setPlayerName(player)"
                 :autofocus="idx === 0"/>
          <span class="delete-button"
                v-if="players.length > minPlayers"
                @click="removePlayer(player.number)">
            &times;
          </span>
        </div>
      </div>
      <div class="button-panel">
        <div class="button"
             @click="addPlayer(null)">
          {{ $t('addPlayer') }}
        </div>
        <div class="button"
             style="margin-left: 0.3rem"
             @click="$router.go(-1)">
          {{ $t('done') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['players', 'maxPlayers', 'minPlayers'])
  },
  methods: {
    ...mapActions(['setPlayerName', 'addPlayer', 'removePlayer'])
  }
}
</script>

<style scoped>

.heading {
  display: block;
  font-size: larger;
  margin-bottom: 0.6rem;
}

.entry-wrapper {
  display: flex;
  justify-content: center;
}

.player-number {
  margin-right: 0.3rem;
}

.addplayer-panel {
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
}

.button-panel {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.delete-button {
  margin-left: 0.3rem;
  font-size: x-large;
  cursor: pointer;
}

.delete-button:hover {
  font-weight: bolder;
}

.input {
  border: 1px solid #ffffff;
  border-radius: 0.3rem;
  background-color: #ffffff22;
  color: white;
  font-family: Oswald;
  padding: 0.2rem;
  margin-bottom: 0.2rem;
}

.input-wrapper {
  display: flex;
}
</style>

